import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Fragment, useState, useEffect, useContext } from "react";
// import { Ecommerce } from '../../.././Ecommerce';
// import HeaderModal from "././HeaderModal";
import { Ecommerce } from "../../../Ecommerce";
import HeaderModal from "../HeaderModal";
import MenuCart from "./MenuCart";

import { useSelector } from "react-redux";



const MobileNavMenu = () => {
  const { t } = useTranslation();

  const [modalShow, setModalShow] = useState(false);
  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };


  const { logout, connectMetamask, account, balance, formatAddress, copyaddress } = useContext(Ecommerce);
  const history = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("isconnected") === 'false') {
      // history('/');
    }
    else if (localStorage.getItem("isconnected") === null) {
      // history('/');
    }
  }, []);

  function fnlogout() {
    logout()
    history('/')
  }
  
  const { cartItems } = useSelector((state) => state.cart);

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">

      <div className="same-style header-search d-none d-lg-block">
      
        <div className="search-content active">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div>

      <div className="same-style header-compare">
        <div className="clear-3 ">
          {account ?
            <>
              <div className="main-menu">
                <nav>
                  <ul>
                    <li>
                      <button className="button fs-6 header_main_button mb-4 pdmin text-white">
                        {formatAddress(account)}
                        {/* <i class="fa fa-copy" onClick={() => copyaddress(account)} /> */}
                      </button>
                      <ul className="submenu mtleft">
                        <li>
                          <Link to="" onClick={() => copyaddress(account)}>
                            Copy Address
                          </Link>
                        </li>
                        <li>
                          <Link to="/user-dashboard">
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <a href="" onClick={fnlogout}>
                            Logout
                          </a>
                        </li>

                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </>
            :
            <>
              <button className="button fs-6 header_main_button mb-4 pdmin text-white" onClick={() => setModalShow(true)}>Connect</button>
              <HeaderModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </>
          }
        </div>

      </div>


    </nav>
  );
};

export default MobileNavMenu;
