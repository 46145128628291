import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const ShopCategories = ({ categories, products, procatid, getProductsByCategory, getSortParams }) => {
  console.log("procatid", procatid);
  return (
    <div className="sidebar-widget">
      <h4 className="pro-sidebar-title">Categories </h4>
      <div className="sidebar-widget-list mt-30">
        {categories ? (
          <ul>

            {categories.map((category, key) => {
              console.log("erjk", key);
              return (

                <li key={key}>
                  <div className="sidebar-widget-list-left">
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"} onClick={getProductsByCategory} className={key === procatid ? "active" : ""} state={{ id: key }}>
                      {/* <button
                        className={category._id === procatid ? "active" : ""}
                        onClick={e => {
                          getSortParams("category", category);
                          setActiveSort(e);
                        }}
                      > */}
                      {" "}
                      <span className="checkmark" />{category}{" "}
                      {/* </button> */}
                    </Link>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          "No categories found"
        )}

      </div>
    </div>
  );
};

ShopCategories.propTypes = {
  categories: PropTypes.array,
  getSortParams: PropTypes.func
};

export default ShopCategories;
