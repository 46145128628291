import { Link, useNavigate } from "react-router-dom";
import { Fragment, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import { Ecommerce } from '../.././Ecommerce';
import axios from 'axios';

import cogoToast from 'cogo-toast';

function AddProductModal({ show, onHide, fetchProducts }) {


    const { library, account, balance, formatAddress, copyaddress, contract, settings } = useContext(Ecommerce);
    const [image, setImage] = useState('');
    const [image2, setImage2] = useState('');
    const [image3, setImage3] = useState('');
    const [category, setCategory] = useState('');
    const [categoryname, setCategoryName] = useState('');
    const [categoryID, setcategoryID] = useState('');
    const [ProductName, setProductName] = useState('');
    const [Description, setDescription] = useState('');
    // const [Qty, setQty] = useState('');
    const [Price, setPrice] = useState('');

    const history = useNavigate();

    const onCloseModal = () => {
        onHide()
    }

    const PriceValue = e => {
        let amtvalue = e.target.value;
        amtvalue = e.target.value.replace(/[^0-9\.]/g, '');

        setPrice(amtvalue);
    }

    // const QtyValue = e => {
    //     let qtyval = e.target.value;
    //     qtyval = e.target.value.replace(/[^0-9\.]/g, '');

    //     setQty(qtyval);
    // }


    function OnImageChange(e) {
        console.log(e.target.files);
        setImage(URL.createObjectURL(e.target.files[0]));
    }


    const [categorylist, setcategorylist] = useState()

    // display category
    // const getCategory = async () => {
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
    //         method: "category",
    //         submethod: "get",
    //         key: process.env.REACT_APP_KEY
    //     })
    //         .then(res => {
    //             console.log("Get Category Data........ : ", res.data)
    //             setcategorylist(res.data.data);
    //         })
    // };

    // get Categories from smart contract
    const getCategory = async () => {
        if (!settings || !library) {
            return
        }
        let contract = await new library.eth.Contract(JSON.parse(settings.smart_contract_abi), settings.smart_contract);
        var get = await contract.methods.getCategories().call();
        setcategorylist(get)
    }

    //get category by category id

    const getCategoryById = async () => {
        if (categorylist) {
            // await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            //     method: "category",
            //     submethod: "getbyid",
            //     id: category,
            //     key: process.env.REACT_APP_KEY
            // })
            //     .then(res => {
            //         setCategoryName(res.data.data.p_category)
            //     })
            // console.log(categorylist(category));
            setCategoryName(categorylist[category])
        }
    };


    useEffect(() => {
        // getCategory();
        getCategoryById();
    }, [account, category, categoryname,library]);

    useEffect(() => {
        getCategory()
    }, [settings,library])
    // insert product details
    const btnSubmit = async () => {
        try {
            if (!settings || !library) {
                return
            }
            const timestemp = new Date().getTime();
            var filename = '';
            var filename2 = null;
            var filename3 = null;
            var filenameArray = []
            console.log("image.mimetype", image);
            //1
            if (image.type === "image/jpeg" || image.type === "image/JPEG" || image.type === "image/jpg" || image.type === "image/JPG" || image.type === "image/png" || image.type === "image/PNG") {
                var extension = image.name.substr(image.name.lastIndexOf(".") + 1);
                filename = timestemp + "a" + "." + extension;
                filenameArray.push(filename)
            } else {
                alert("Only png and jpg files are supported")
                return
            }
            //2
            if (image2) {
                if (image2.type === "image/jpeg" || image2.type === "image/JPEG" || image2.type === "image/jpg" || image2.type === "image/JPG" || image2.type === "image/png" || image2.type === "image/PNG") {
                    var extension = image2.name.substr(image.name.lastIndexOf(".") + 1);
                    filename2 = timestemp + "b" + "." + extension;
                    filenameArray.push(filename2)
                } else {
                    alert("Only png and jpg files are supported")
                    return
                }
            }
            //3
            if (image3) {
                if (image3.type === "image/jpeg" || image3.type === "image/JPEG" || image3.type === "image/jpg" || image3.type === "image/JPG" || image3.type === "image/png" || image3.type === "image/PNG") {
                    var extension = image3.name.substr(image.name.lastIndexOf(".") + 1);
                    filename3 = timestemp + "c" + "." + extension;
                    filenameArray.push(filename3)
                } else {
                    alert("Only png and jpg files are supported")
                    return
                }
            }
            let contract = await new library.eth.Contract(JSON.parse(settings.smart_contract_abi), settings.smart_contract);
            // console.log("contract", Description,Price);
            // const timestemp = new Date().getTime();

            // 

            console.log("filenameAr", filenameArray.toString());
            await contract.methods.addProduct(ProductName, category, filenameArray.toString(), Description, Price).send({ from: account },
                async function (err, transactionId) {
                    if (err) {

                        return 0
                    }
                    cogoToast.loading('Wait your transaction is in process', { position: "bottom-left" });
                    console.log("Approval successfully", transactionId, categoryname);
                    const formData = new FormData();
                    formData.append('method', "products");
                    formData.append('submethod', "insert");
                    formData.append('p_name', ProductName);
                    formData.append('p_desc', Description);
                    formData.append('p_category_id', category);
                    formData.append('hash', transactionId);
                    formData.append('p_category', categoryname);
                    formData.append('file', image);
                    formData.append('file2', image2);
                    formData.append('file3', image3);
                    formData.append('filename', filename);
                    formData.append('filename2', filename2);
                    formData.append('filename3', filename3);
                    // formData.append('qty', Qty);
                    formData.append('rate', Price);
                    formData.append('seller_address', account);
                    formData.append('key', process.env.REACT_APP_KEY);
                    await axios.post(process.env.REACT_APP_BACKEND_LINK, formData, {
                    }).then(res => {
                        console.log("res.data", res.data);
                        // fetchProducts()
                        // cogoToast.success("Product Added Successfully", { position: "bottom-left" });
                        onHide();
                    })
                        .catch((err) => {
                            console.log(err);
                        });
                }).then((res) => {
                    fetchProducts()
                    cogoToast.success("Product Added Successfully", { position: "bottom-left" });
                }).catch((error) => {
                    cogoToast.error('Transaction failed', { position: "bottom-left" });
                    console.log("Transaction failed")
                });
            // var add = await contract.methods.addProduct().call();
        } catch (err) {
            console.log("err", err);
        }
    }

    return (
        <Modal show={show} onHide={onCloseModal} className="product-quickview-modal-wrapper modal_dialog_box ">
            <Modal.Header closeButton></Modal.Header>

            <div className="modal-body">
                <div className="row">

                    <div className="billing-info-wrap">
                        <h3>Product Add</h3>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="billing-select mb-20">
                                    <label>Select Category *</label>

                                    <select onChange={(e) => setCategory(e.target.value)}>
                                        <option>Select a Category</option>
                                        {
                                            categorylist?.map((item, index) => {
                                                return (
                                                    <>
                                                        <option key={index} value={index}>{item}</option>
                                                    </>
                                                )
                                            })
                                        }

                                    </select>

                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="billing-info mb-20"><label>Product Name *</label><input type="text" placeholder="Enter Product Name" onChange={(e) => setProductName(e.target.value)} /></div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="mb-20"><label>Select Image *</label>
                                    <input type="file" onChange={(e) => setImage(e.target.files[0])} accept="image/*" /><br />
                                    <div className="col-lg-12 col-md-12">
                                        <div className="mb-20"><label>Select Image2</label>
                                            <input type="file" onChange={(e) => setImage2(e.target.files[0])} accept="image/*" /><br />
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="mb-20"><label>Select Image3</label>
                                            <input type="file" onChange={(e) => setImage3(e.target.files[0])} accept="image/*" /><br />
                                        </div>
                                    </div>



                                    {/* {image ?
                                        <img src={image} width={200} height={170} />
                                        :
                                        ''
                                    } */}
                                </div>
                            </div>
                            <div className="additional-info-wrap">
                                <div className="additional-info"><label>Description</label>
                                    <textarea placeholder="Enter Description" onChange={(e) => setDescription(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <div className="billing-info mb-20"><label>Price *</label><input type="text" value={Price} onChange={PriceValue} placeholder="Enter amount in BRISE" /></div>
                        </div>
                        {/* <div className="col-lg-12 col-md-12">
                            <div className="billing-info mb-20"><label>Quantity *</label><input type="text" value={Qty} onChange={QtyValue} /></div>
                        </div> */}
                    </div>


                    <center>
                        <div className="same-style header-compare">
                            <div className="clear-3 ">
                                <br></br>
                                <button className="button fs-6 header_main_button mb-4" onClick={btnSubmit}>Submit</button>
                            </div>
                        </div>
                    </center>
                </div>
            </div>
        </Modal>
    );
}

AddProductModal.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool
};

export default AddProductModal;
