import { Fragment, useState, useEffect, useContext } from 'react';
import Paginator from 'react-hooks-paginator';
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom"
import { getSortedProducts } from '../../helpers/product';
import SEO from "../../components/seo";
import LayoutOne from '../../layouts/LayoutOne';
import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
import ShopSidebar from '../../wrappers/product/ShopSidebar';
import ShopTopbar from '../../wrappers/product/ShopTopbar';
import ShopProducts from '../../wrappers/product/ShopProducts';

import NewProductGrid from "../../wrappers/product/NewProductGrid";
import NotFound from '../other/NotFound';
import { Ecommerce } from '../../Ecommerce';
import PersistProvider from '../../store/providers/persist-provider';
import Spinner from 'react-bootstrap/Spinner';

import BlogPagination from "../../wrappers/blog/BlogPagination";
import BlogPostsNoSidebar from "../../wrappers/blog/BlogPostsNoSidebar";



const ProductList = () => {
    const [layout, setLayout] = useState('grid three-column');
    const [sortType, setSortType] = useState('');
    const [sortValue, setSortValue] = useState('');
    const [filterSortType, setFilterSortType] = useState('');
    const [filterSortValue, setFilterSortValue] = useState('');
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);
    // const [sortedProducts, setSortedProducts] = useState(null);
    const [sortedProducts, setSortedProducts] = useState([]);
    const { products } = useSelector((state) => state.product);

    const pageLimit = 15;
    let { pathname } = useLocation();

    const getLayout = (layout) => {
        setLayout(layout)
    }

    const getSortParams = (sortType, sortValue) => {
        setSortType(sortType);
        setSortValue(sortValue);
    }

    const getFilterSortParams = (sortType, sortValue) => {
        setFilterSortType(sortType);
        setFilterSortValue(sortValue);
    }
    const { searchItem } = useContext(Ecommerce);



    // useEffect(() => {
    //     let sortedProducts = getSortedProducts(products, sortType, sortValue);
    //     const filterSortedProducts = getSortedProducts(sortedProducts, filterSortType, filterSortValue);
    //     sortedProducts = filterSortedProducts;
    //     if (searchItem === "") {
    //         setSortedProducts(sortedProducts);
    //     } else {
    //         let sotedProd = sortedProducts?.filter(item => item.name.includes(searchItem))
    //         setSortedProducts(sotedProd);
    //     }
    //     setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
    // }, [offset, products, sortType, sortValue, filterSortType, filterSortValue, searchItem]);


    useEffect(() => {
        console.log("STate DAT", products);
        let sortedProducts = getSortedProducts(products, sortType, sortValue);
        const filterSortedProducts = getSortedProducts(sortedProducts, filterSortType, filterSortValue);
        sortedProducts = filterSortedProducts;
        setSortedProducts(sortedProducts);
        setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
    }, [offset, products, sortType, sortValue, filterSortType, filterSortValue]);


    const [productItem, setProductItem] = useState("")



    const [seconds, setSeconds] = useState(0);

    // console.log("sortedPr/oducts", sortedProducts);
    console.log("searchItem", searchItem);



    // console.log("sortedProducts.filter(prods => prods.name.includes(searchItem))", sortedProducts.filter(prods => prods.name.includes(searchItem)));
    return (
        <Fragment>
            <SEO
                titleTemplate="Shop Page"
                description="Shop page of flone react minimalist eCommerce template."
            />
            {console.log("sortedProducts", sortedProducts)}
            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Shop", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />
                {/* <div class="text-center align-items-center justify-content-center" style={{ marginTop: "200px", marginBottom: "400px" }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div> */}


                {/* <div className="shop-area pt-95 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 order-2 order-lg-1">
                               
                            </div>


                         

                            {
                                sortedProducts === null ? <PersistProvider /> : sortedProducts.length > 0
                                    ?

                                    <>
                                      
                                        <div className="col-lg-12 order-1 order-lg-2">
                                            <>
                                                <NewProductGrid category="accessories" limit={10} />
                                        
                                                <div className="pro-pagination-style text-center mt-30">
                                                    <Paginator
                                                        totalRecords={sortedProducts.length}
                                                        pageLimit={pageLimit}
                                                        pageNeighbours={2}
                                                        setOffset={setOffset}
                                                        currentPage={currentPage}
                                                        setCurrentPage={setCurrentPage}
                                                        pageContainerClass="mb-0 mt-0"
                                                        pagePrevText="«"
                                                        pageNextText="»"
                                                    />
                                                </div>
                                            </>
                                         
                                        </div>
                                    </>
                                    :

                                    <NotFound />
                            }
                        </div>
                    </div>
                </div> */}




                {
                    sortedProducts.length == 0
                        ?
                        <div class="text-center align-items-center justify-content-center" style={{ marginTop: "200px", marginBottom: "400px" }}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                       
                        :
                        <div className="shop-area pt-95 pb-100">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3 order-2 order-lg-1">

                                    </div>
                                    {
                                        sortedProducts.filter(prods => prods.name.includes(searchItem)).length != 0
                                            ?

                                            <>

                                                <div className="col-lg-12 order-1 order-lg-2">
                                                    <>
                                                        <NewProductGrid category="accessories" sortedProducts={sortedProducts} limit={10} />
                                                    
                                                        <div className="pro-pagination-style text-center mt-30">
                                                            <Paginator
                                                                totalRecords={sortedProducts.length}
                                                                pageLimit={pageLimit}
                                                                pageNeighbours={2}
                                                                setOffset={setOffset}
                                                                currentPage={currentPage}
                                                                setCurrentPage={setCurrentPage}
                                                                pageContainerClass="mb-0 mt-0"
                                                                pagePrevText="«"
                                                                pageNextText="»"
                                                            />
                                                        </div>
                                                    </>

                                                </div>
                                            </>
                                            :

                                            <NotFound />
                                    }

                                </div>
                            </div>
                        </div>
                }




                {/* <div className="blog-area pt-100 pb-100 blog-no-sidebar">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mr-20">
                                    <div className="row">
                                     
                                        <BlogPostsNoSidebar />
                                    </div>

                             
                                    <BlogPagination />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}


            </LayoutOne>




        </Fragment>
    )
}


export default ProductList;