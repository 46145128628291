
import { useLocation, Link } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import UserPanelSidebar from "./UserPanelSidebar";

import { Fragment, useState, useEffect } from 'react';

const ProductDetails = () => {

  let { pathname } = useLocation();

  return (
    <Fragment>
      <SEO
        titleTemplate="Product Details"
        description=""
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            { label: "Product Details", path: process.env.PUBLIC_URL + pathname }
          ]}
        />
        <div className="blog-area pt-100 pb-100">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-9">
                <div className="ml-20">

                  <div className="row">

                    <div class="productdetailsbtn">
                      <Link to="/orders"><i class="fa fa-arrow-left" aria-hidden="true"></i></Link>
                    </div>
                    <br /> <br /> <br /> <br />
                    <Fragment>
                      <h3 className="cart-page-title">List of Products</h3>
                      <div className="row">
                        <div className="col-12">
                          <div className="table-content table-responsive cart-table-content">
                            <table className="tbl">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Category</th>
                                  <th>Item Name</th>
                                  <th>Quantity</th>
                                  <th>Rate</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>

                                <tr>

                                  <td>
                                    1
                                  </td>

                                  <td>
                                    Top
                                  </td>

                                  <td>
                                    Fancy Top
                                  </td>

                                  <td>
                                    6
                                  </td>

                                  <td>
                                    550
                                  </td>

                                  <td>
                                    3300
                                  </td>

                                </tr>

                                <tr>

                                  <td>
                                    2
                                  </td>

                                  <td>
                                    Watch
                                  </td>

                                  <td>
                                    Smart Watch
                                  </td>

                                  <td>
                                    2
                                  </td>

                                  <td>
                                    1100
                                  </td>

                                  <td>
                                    2200
                                  </td>

                                </tr>
                                <tr>

                                  <td>
                                    3
                                  </td>

                                  <td>
                                    Dress
                                  </td>

                                  <td>
                                    Women Dress
                                  </td>

                                  <td>
                                    3
                                  </td>

                                  <td>
                                    800
                                  </td>

                                  <td>
                                    2400
                                  </td>

                                </tr>
                                <tr>

                                  <td>
                                    4
                                  </td>

                                  <td>
                                    Jacket
                                  </td>

                                  <td>
                                    Jeans jacket
                                  </td>

                                  <td>
                                    3
                                  </td>

                                  <td>
                                    150
                                  </td>

                                  <td>
                                    450
                                  </td>

                                </tr>

                              </tbody>
                            </table>


                          </div>
                        </div>
                      </div>


                    </Fragment>

                  </div>

                </div>
              </div>
              <div className="col-lg-3">

                <UserPanelSidebar />

              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default ProductDetails;
