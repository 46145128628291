

import { InjectedConnector } from '@web3-react/injected-connector';

import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const POLLING_INTERVAL = 12000;

const RPC_URLS = {
    97 : "https://data-seed-prebsc-1-s3.binance.org:8545"
};
export const RPC = "https://testnet-rpc.brisescan.com";
export const injected = new InjectedConnector({
    supportedChainIds: [64668]
})

export const chainData = [
    {
        chainId: '0xFC9C',
        chainName: 'BRISE Testnet',
        rpcUrls: ['https://testnet-rpc.brisescan.com'],
        blockExplorerUrls: ['https://testnet-explorer.brisescan.com'],
    },
]

export const walletconnect = new WalletConnectConnector({
    rpc: RPC_URLS,
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    pollingInterval: POLLING_INTERVAL
});

