import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Ecommerce } from "../../Ecommerce";
import { useContext } from "react";

const BlogPostsNoSidebar = () => {
  const { library, settings } = useContext(Ecommerce);

  const [categorylist, setcategorylist] = useState([])
  const [imgs, setimgs] = useState([])

  // const getCategory = async () => {
  //   await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
  //     method: "category",
  //     submethod: "get",
  //     key: process.env.REACT_APP_KEY
  //   })
  //     .then(res => {
  //       console.log("Get Category Data........ : ", res.data)
  //       setcategorylist(res.data.data)
  //     })
  // };

  const getCategory = async () => {
    if (!settings || !library) {
      return
    }
    let contract = await new library.eth.Contract(JSON.parse(settings.smart_contract_abi), settings.smart_contract);
    var get = await contract.methods.getCategories().call();
    var getimg = await contract.methods.getCatimage().call();
    // console.log("getimg", getimg);
    setimgs(getimg)
    setcategorylist(get)
  }

  useEffect(() => {
    getCategory();
  }, [settings,library]);

  return (
    <Fragment>
      {
        categorylist?.map((item, i) => {

          return (
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="blog-wrap-2 mb-30">
                <div className="blog-img-2">
                  <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"} state={{ id: i }}>
                    <img
                      src={process.env.REACT_APP_CATEGORY_IMAGE_PATH + imgs[i]}
                      alt={item}
                    />
                  </Link>
                </div>
                <div className="blog-content-2">
                  <h4 className="text-center">
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"} state={{ id: i }}>
                      {/* <h4 className="fw-bold">{item}</h4> */}
                      <h4 className="fw-bold">View Products</h4>
                    </Link>
                  </h4>

                </div>
              </div>
            </div>
          )
        })
      }
    </Fragment>
  );
};

export default BlogPostsNoSidebar;
