import React, { useContext, useEffect, Fragment, useState, useInsertionEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import { Ecommerce } from '../.././Ecommerce';

import web3 from 'web3';
import axios from 'axios';

import { Link, useLocation, useNavigate } from "react-router-dom";


import Spinner from 'react-bootstrap/Spinner';

function HeaderModal({ show, onHide }) {
  const onCloseModal = () => {
    onHide()
  }

  // connect metamask using context  

  const { logout, connectMetamask, connectWallet, account, balance, formatAddress, copyaddress } = useContext(Ecommerce);

  const history = useNavigate();

  function connectmask() {
    connectMetamask();
  }

  useEffect(() => {
    console.log("account...", account)
  }, [account]);

  //set timer loader

  const [isClick, setIsClick] = useState(false);

  const handleButtonClick = () => {
    setTimeout(() => {
      connectmask();
    }, 2000);

  }


  return (
    <Modal show={show} onHide={onCloseModal} className="product-quickview-modal-wrapper modal_dialog_box ">
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body">
        <div className="row">

          {isClick ?
            <>
              {!account ?
                <>
                  <center>
                    <div className="same-style header-compare">
                      <div className="clear-3 ">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    </div>
                  </center>
                </>
                :
                window.location.reload()
              }
            </>
            :
            <>
              <center>
                <div className="same-style header-compare">
                  <div className="clear-3 ">
                    <button onClick={() => { handleButtonClick(); setIsClick(true); }} className="button fs-6 header_main_button mb-4" >Metamask <img src={`${process.env.REACT_APP_SITE_URL}/assets/img/icon-img/metamask.png`} style={{ "width": "23px" }} /> </button>

                  </div>
                </div>
              </center>

              <center>
                <div className="same-style header-compare">
                  <div className="clear-3 ">
                    <button className="button fs-6 header_main_button mb-4" onClick={connectWallet} >WalletConnect 
                    <img src={`${process.env.REACT_APP_SITE_URL}/assets/img/icon-img/icon`} style={{ "width": "26px" }} /></button>

                  </div>

                </div>
              </center>

              <center>
                <div className="same-style header-compare">
                  <div className="clear-3 ">
                    <button className="button fs-6 header_main_button mb-4" >Trustwallet  <img src={`${process.env.REACT_APP_SITE_URL}/assets/img/icon-img/TWT.png`} style={{ "width": "26px" }} /></button>

                  </div>
                </div>
              </center>
            </>
          }
        </div>
      </div>
    </Modal>
  );
}

HeaderModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool
};

export default HeaderModal;
