
import { Link, useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import PropTypes from "prop-types";

import clsx from "clsx";
import HeaderModal from "./HeaderModal";
// import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";


import { Ecommerce } from '../.././Ecommerce';
import { useEffect } from "react";

const IconGroup = ({ iconWhiteClass }) => {

  const { logout, account, formatAddress, copyaddress, setSearchItem } = useContext(Ecommerce);
  const history = useNavigate();

  const [modalShow, setModalShow] = useState(false);
  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };


  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  useEffect(() => {
    if (!account) {
      setModalShow(true)
    } else {
      setModalShow(false)
    }
  }, [account])

  function fnlogout() {
    logout()
    history('/')
  }

  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)} >

      {(window.location.pathname === '/user-dashboard' || window.location.pathname === '/orders' || window.location.pathname === '/products' || window.location.pathname === '/transactions' || window.location.pathname === '/product-details')
        ?
        ""
        : <>
          <div className="same-style header-search d-none d-lg-block">

            <div className="search-content active">
              <form action="#">
                <input type="text" placeholder="Search" onChange={(e) => setSearchItem(e.target.value)} />
                <Link to="/product-list">
                  <button className="button-search">
                    <i className="pe-7s-search" />
                  </button>
                </Link>
              </form>

            </div>

          </div>
        </>
      }

      <div className="same-style account-setting d-none d-lg-block">

        <div className="account-dropdown">
          <ul>
            <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>Login</Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                Register
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/my-account"}>
                my account
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="same-style header-compare">
        <div className="clear-3 ">
          {account ?
            <>
              <div className="main-menu">
                <nav>
                  <ul>
                    <li>
                      <button className="button fs-6 header_main_button mb-4 pdmin text-white d-none d-lg-block">
                        {formatAddress(account)}
                        {/* <i class="fa fa-copy" onClick={() => copyaddress(account)} /> */}
                      </button>
                      <ul className="submenu mtleft">
                        <li>
                          <Link to="" onClick={() => copyaddress(account)}>
                            Copy Address
                          </Link>
                        </li>
                        <li>
                          <Link to="/user-dashboard">
                            Dashboard
                          </Link>
                        </li>
                        <li>
                          <a href="" onClick={fnlogout}>
                            Logout
                          </a>
                        </li>

                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </>
            :
            <>
              <button className="button fs-6 header_main_button mb-4 pdmin text-white d-none d-lg-block" onClick={() => setModalShow(true)}>Connect</button>
              <HeaderModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </>
          }
        </div>


      </div>

      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button mx-3 mt-2"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu fs-2" />
        </button>
      </div>

    </div>


  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
};



export default IconGroup;
