import { Link, useNavigate } from "react-router-dom";
import { Fragment, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import { Ecommerce } from '../.././Ecommerce';
import axios from 'axios';

import cogoToast from 'cogo-toast';

function AcceptBuyerModal({ show, onHide, id, getPendingSellOrders, selecttype }) {

    const { account } = useContext(Ecommerce);

    const onCloseModal = () => {
        onHide()
    }

    // update order

    async function btnAcceptBuyer(id) {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "order", submethod: "updateacceptbuyer", id: id, key: process.env.REACT_APP_KEY })
            .then(res => {
                if (res.data) {
                    getPendingSellOrders()
                    onHide()
                    cogoToast.success("Your order has been received.", { position: "bottom-left" });
                }
            })
    }


    useEffect(() => {
    }, [account]);

    return (
        <Modal show={show} onHide={onCloseModal} className="product-quickview-modal-wrapper modal_dialog_box ">
            <Modal.Header closeButton></Modal.Header>

            <div className="modal-body">
                <div className="row">

                    <center>
                        <div className="same-style header-compare">
                            <center>
                                <img src="../assets/img/receive.png" width={60} height={60} />
                            </center> <br />
                            <div className="clear-3 ">

                                <h3>Item Received?</h3>

                            </div>
                        </div>
                    </center>

                    <center>
                        <div className="same-style header-compare">
                            <div className="clear-3 ">

                                <br></br>
                                <button className="button fs-6 header_main_button mb-4" onClick={() => { btnAcceptBuyer(id); }}>Yes</button>&nbsp;&nbsp;&nbsp;
                                <button className="button fs-6 header_main_button mb-4" onClick={onCloseModal}>No</button>

                            </div>

                        </div>
                    </center>



                </div>
            </div>
        </Modal>
    );
}

AcceptBuyerModal.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool
};

export default AcceptBuyerModal;
