import React, { useEffect, useState } from 'react';

import { createContext } from "react";
import App from "./App";
import { injected, chainData, walletconnect } from './Connectors/connectors';
import axios from 'axios';
// import toast from 'react-simple-toasts';
import copy from "copy-to-clipboard";

import cogoToast from 'cogo-toast';
import { useWeb3React } from '@web3-react/core';
window.Buffer = require("buffer/").Buffer;

export const Ecommerce = createContext();

const Ecommercepage = () => {

    var { active, account, balance, activate, deactivate, library, chainId, error } = useWeb3React();

    // const [balance, setBalance] = useState(null);

    const [urlReferral, seturlReferral] = useState(null);
    const [wconn, setwconn] = useState(false);
    const [hasreferral, sethasreferral] = useState(false);


    const [searchItem, setSearchItem] = useState("")
    const [settings, setsettings] = useState([])

    const formatAddress = (address) => {
        return address.substr(0, 5) + "..." + address.substr(-5, 5);
    }

    const copyaddress = (address) => {
        copy(address);
        cogoToast.success("Copied", { position: "bottom-left" });
    }

    const connectMetamask = async () => {
        changeNetwork()
    }

    const connectWallet = async () => {
        try {
            var connect = await activate(walletconnect);

            console.log("----->walletconnect", walletconnect)
            console.log("----->connect", connect)

            localStorage.igtStaking = 1;
            localStorage.setItem("isconnected", true)
            cogoToast.success("Wallet Connected Successfully...", { position: "bottom-left" });
        }
        catch (error) {
            alert(error)
        }
    }
    const getSetting = async () => {
        await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
            method: "setting",
            submethod: "get",
            key: process.env.REACT_APP_KEY
        }).then(res => {
            if (res.data.error) {
                return ''
            }
            setsettings(res.data.data);
        })
    }
    const contract = async () => {
        if (!settings || !library) {
            return
        }
        let contract = await new library.eth.Contract(JSON.parse(settings.smart_contract_abi), settings.smart_contract);
        return contract
    }
    useEffect(() => {
        if (localStorage.getItem("isconnected") === 'true') {
            connectMetamask()
        }
        getSetting()
    }, [library])

    useEffect(() => {
        // Check if library is defined before using it
        if (library) {
            getsmcs();
        }
    }, [account]);


    const changeNetwork = async () => {
        if (!window.ethereum) throw new Error("No crypto wallet found");

        if (window.ethereum) {
            Success()
        }
        await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: chainData,
        });

    };

    const Success = async () => {
        try {
            var connect = await activate(injected);
            localStorage.igtStaking = 1;
            localStorage.setItem("isconnected", true)

            cogoToast.success("Metamask Connected Successfully...", { position: "bottom-left" });
        }
        catch (error) {
            alert(error)
        }
    };

    const getsmcs = async () => {
        var b = library.eth.getBalance(account);
        return { balance: b };
    }


    // useEffect(() => {
    //     getsmcs();
    // }, [account])

    useEffect(() => {
        console.log("error", error);
    }, [error])


    const logout = () => {
        try {
            deactivate();
            localStorage.removeItem('igtStaking');
            localStorage.setItem("isconnected", false)
            window.location.reload(true);
        }
        catch (error) {
            alert(error)
        }
    }

//     console.log({library}); // Check if myObject is defined
// console.log(library.eth); // Check if the 'eth' property is accessible


    return (
        <Ecommerce.Provider value={{ connectMetamask, connectWallet, logout, searchItem, setSearchItem, balance, library, active, account, formatAddress, copyaddress, logout, getsmcs, contract, settings }}>
            <App />
        </Ecommerce.Provider>
    )
}

export default Ecommercepage;