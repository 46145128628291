
import { useLocation, Link, useNavigate } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import UserPanelSidebar from "./UserPanelSidebar";

import { Fragment, useState, useEffect, useContext, useMemo } from 'react';
import Paginator from 'react-hooks-paginator';
import { useSelector } from "react-redux";
import { getSortedProducts } from '../../helpers/product';
import axios from 'axios';
import { Ecommerce } from '../.././Ecommerce';
import AcceptModal from "./AcceptModal";
import RejectModal from "./RejectModal";


const countOnPage = 10;
const range = 2;

const UserDashboard = () => {

  const { account, formatAddress, settings, copyaddress, library } = useContext(Ecommerce);
  const currency = process.env.REACT_APP_TICKER;

  let { pathname } = useLocation();

  const [sortType, setSortType] = useState('');
  const [sortValue, setSortValue] = useState('');
  const [filterSortType, setFilterSortType] = useState('');
  const [filterSortValue, setFilterSortValue] = useState('');
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);
  const { products } = useSelector((state) => state.product);

  const pageLimit = 15;


  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);

  const [acceptid, setacceptid] = useState('');
  const [rejectid, setrejectid] = useState('');

  const [udpateData, setudpateData] = useState(false);

  const [orderlistdata, setorderlistdata] = useState([]);
  const [userdashboarddata, setuserdashboarddata] = useState([]);
  const [loading1, setLoading1] = useState(false);

  //pagination 

  const [page, setPage] = useState(1);

  const [datalength, setdatalength] = useState(0);

  const rangeStart = useMemo(() => {
    const start = page - range;
    return start > 0 ? start : 1;

  }, [page]);

  const rangeEnd = useMemo(() => {
    const end = page + range;
    return end < datalength ? end : datalength;
  }, [page, datalength]);

  const pages = useMemo(() => {
    return Array.from({ length: rangeEnd }, (_, index) => index + 1).slice(
      rangeStart - 1
    );
  }, [rangeStart, rangeEnd]);

  //get user dashboarddata
  const getUserDashboardData = async () => {
    if (!settings || !library) {
      return
    }
    let contract = await new library.eth.Contract(JSON.parse(settings.smart_contract_abi), settings.smart_contract);
    var get = await contract.methods.getproductbyuser(account).call();
    var getbuyer = await contract.methods.getorderbybuyer(account).call();
    var getseller = await contract.methods.getorderbyseller(account).call();
    console.log("getbuyer", getbuyer.length, "getseller", getseller);
    var ttlpr = 0;
    var ttlbuy = 0;
    var ttlsell = 0;
    var ttlpenbuy = 0;
    var ttlpensell = 0;
    var ttlinc = 0;
    var pendorder = []
    for (let i = 0; i < get.length; i++) {
      const e = get[i];
      if (e.seller !== '0x0000000000000000000000000000000000000000') {
        ttlpr = ttlpr + 1
      }
    }

    for (let i = 0; i < getbuyer.length; i++) {
      const e = getbuyer[i];
      if (e.buyer !== '0x0000000000000000000000000000000000000000') {
        ttlbuy = ttlbuy + 1
        if (e.status !== "2" || e.status !== "3") {
          ttlpenbuy = ttlpenbuy + 1
        }
      }
    }

    for (let i = 0; i < getseller.length; i++) {
      const e = getseller[i];
      if (e.seller !== '0x0000000000000000000000000000000000000000') {
        ttlsell = ttlsell + 1
        if (e.status !== "2" || e.status !== "3") {
          ttlpensell = ttlpensell + 1
          var getprd = await contract.methods.products(e.productId).call();
          pendorder.push({
            amount: e.amount,
            buyer: e.buyer,
            buyerOk: e.buyerOk,
            feeRatio: e.feeRatio,
            feeWallet: e.feeWallet,
            odertime: e.odertime,
            orderId: e.orderId,
            productId: e.productId,
            qty: e.qty,
            prdname: getprd.name,
            received: e.received,
            seller: e.seller,
            sellerOk: e.sellerOk,
            status: e.status,
          })
        }
        if (e.status === "2") {
          ttlinc = ttlinc + Number(library.utils.fromWei(e.amount))
        }
      }
    }
    // console.log("sasasss", pendorder[0].amount);
    //pending orders
    setorderlistdata(pendorder)
    setdatalength(Math.ceil(pendorder.length / countOnPage));
    setuserdashboarddata({ totalproduct: ttlpr, totalbuy: ttlbuy, totalsell: ttlsell, totalbuypending: ttlpenbuy, totalsellpending: ttlpensell, totalincome: ttlinc })
    // console.log("get by usre dasg as", get, get.length);
  };
  // const getUserDashboardData = async () => {
  //   await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
  //     method: "user",
  //     submethod: "getdashdatabyaddress",
  //     address: account,
  //     key: process.env.REACT_APP_KEY
  //   })
  //     .then(res => {
  //       console.log("get user dashboard data...>>", res.data.data[0]);
  //       setuserdashboarddata(res.data.data[0])
  //     })
  // };

  //get list of pending sells order
  const getPendingSellOrders = async () => {
    await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "order",
      submethod: "getbysell",
      address: account,
      key: process.env.REACT_APP_KEY
    })
      .then(res => {
        setLoading1(false);
        if (res.data.error) {
          return ''
        }
        // setorderlistdata(res.data.data);
        // setdatalength(Math.ceil(res.data.dazta.length / countOnPage));
      })
  };


  useEffect(() => {
    let sortedProducts = getSortedProducts(products, sortType, sortValue);
    const filterSortedProducts = getSortedProducts(sortedProducts, filterSortType, filterSortValue);
    sortedProducts = filterSortedProducts;
    setSortedProducts(sortedProducts);
    setCurrentData(sortedProducts.slice(offset, offset + pageLimit));

    getUserDashboardData();
    // getPendingSellOrders();

  }, [offset, library,products, udpateData, sortType, sortValue, filterSortType, filterSortValue, account]);

  return (

    <Fragment>
      <SEO
        titleTemplate="Fashion Home"
        description="Fashion home of flone react minimalist eCommerce template."
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            { label: "User Dashboard", path: process.env.PUBLIC_URL + pathname }
          ]}
        />

        <div className="blog-area pt-100 pb-100">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-9">
                <div className="ml-20">

                  <div className="row">
                    <Fragment>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="blog-wrap-2 mb-30 bgcl">
                          <div className="blog-content-2">
                            <h4>
                              Total Product
                            </h4>
                            <p>
                              {userdashboarddata.totalproduct}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="blog-wrap-2 mb-30 bgcl">
                          <div className="blog-content-2">
                            <h4>
                              Total Buy
                            </h4>
                            <p>
                              {userdashboarddata.totalbuy}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="blog-wrap-2 mb-30 bgcl">
                          <div className="blog-content-2">
                            <h4>
                              Total Sell
                            </h4>
                            <p>
                              {userdashboarddata.totalsell}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="blog-wrap-2 mb-30 bgcl">
                          <div className="blog-content-2">
                            <h4>
                              Total Income
                            </h4>
                            <p>
                              {userdashboarddata.totalincome}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="blog-wrap-2 mb-30 bgcl">
                          <div className="blog-content-2">
                            <h4>
                              Total B.Pending
                            </h4>
                            <p>
                              {userdashboarddata.totalbuypending}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="blog-wrap-2 mb-30 bgcl">
                          <div className="blog-content-2">
                            <h4>
                              Total S.Pending
                            </h4>
                            <p>
                              {userdashboarddata.totalsellpending}
                            </p>
                          </div>
                        </div>
                      </div>

                    </Fragment>
                  </div>

                  <Fragment>
                    <h3 className="cart-page-title">List of Pending Sell Orders</h3>
                    <div className="row">
                      <div className="col-12">
                        <div className="table-content table-responsive cart-table-content">
                          <table className="tbl">
                            <thead>
                              <tr>
                                <th>Status</th>
                                <th>#</th>
                                <th>Order ID</th>
                                <th>Date</th>
                                <th>Product Details</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>

                              {orderlistdata?.slice((page - 1) * countOnPage, page * countOnPage).map((e, ind) => {

                                var date = new Date(e.odertime * 1000)
                                return (
                                  <>
                                    {e.status === "2" || e.status === "3" ?
                                      ''
                                      :
                                      <tr>
                                        <td>
                                          {e.status === '0' ?
                                            <>
                                              <div className="product-wrap mb-45">
                                                <div class="product-img-badges">
                                                  <span class="purple">Processing</span>
                                                </div>
                                              </div>

                                              <a onClick={() => { setModalShow(true); setacceptid(e.orderId) }}>
                                                <img src="../assets/img/accept.png" width={40} height={40} />
                                              </a>
                                              &nbsp;
                                              <a onClick={() => { setModalShow2(true); setrejectid(e.orderId) }}>
                                                <img src="../assets/img/reject.png" width={40} height={40} />
                                              </a>
                                              <br />

                                            </>
                                            : e.status === "1" ?
                                              <>
                                                <div className="product-wrap mb-45">
                                                  <div class="product-img-badges">
                                                    <span class="purple">Shipping</span>
                                                  </div>
                                                </div>
                                              </>
                                              : e.status === "3" ?
                                                <>
                                                  <div className="product-wrap mb-45">
                                                    <div class="product-img-badges">
                                                      <span class="purple">Rejected</span>
                                                    </div>
                                                  </div>
                                                </>
                                                :
                                                <>
                                                  <div className="product-wrap mb-45">
                                                    <div class="product-img-badges">
                                                      <span class="purple">Sold</span>
                                                    </div>
                                                  </div>
                                                </>
                                          }
                                        </td>

                                        <td>{ind + 1}</td>
                                        <td>
                                          {formatAddress(e.orderId)}&nbsp;
                                          <Link to="" onClick={() => copyaddress(e.orderId)}>
                                            <i class="fa fa-copy" />
                                          </Link>
                                        </td>
                                        <td>{date.toLocaleDateString('default')} {date.toLocaleTimeString("default")}</td>
                                        <td>
                                          <b>Item:</b> {e.prdname} <br />
                                          <b>Qty:</b> {e.qty} <br />
                                          <b>Price:</b> {e.p_rate} {currency} <br />
                                          <b>Seller:</b>&nbsp;
                                          {formatAddress(e.seller)}&nbsp;
                                          <Link to="" onClick={() => copyaddress(e.seller)}>
                                            <i class="fa fa-copy" />
                                          </Link>
                                        </td>

                                        <td>
                                          {library.utils.fromWei(String(e.amount), 'ether')} {currency}
                                        </td>

                                      </tr>
                                    }
                                  </>

                                );
                              })}

                            </tbody>
                          </table>


                        </div>
                      </div>
                    </div>

                    <div className="pro-pagination-style text-center mt-30">

                      {pages?.map((item) => {
                        return (
                          <>
                            <span class={page == item ? "page-item active" : "page-item"} type="button" key={item} onClick={() => setPage(item)}>
                              <Paginator
                                totalRecords={item}
                                pageLimit={pageLimit}
                                pageNeighbours={2}
                                setOffset={setOffset}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                pageContainerClass="mb-0 mt-0"
                                pagePrevText="«"
                                pageNextText="»"
                              />
                            </span>

                          </>
                        );
                      })}


                    </div>

                  </Fragment>

                </div>
              </div>
              <div className="col-lg-3">

                <UserPanelSidebar />

              </div>
            </div>
          </div>
        </div>

      </LayoutOne>

      <AcceptModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        id={acceptid}
        setudpateData={setudpateData}
        getPendingSellOrders={getPendingSellOrders}
      />

      <RejectModal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        id={rejectid}
        setudpateData={setudpateData}
        getPendingSellOrders={getPendingSellOrders}
      />

    </Fragment>


  );
};

export default UserDashboard;
