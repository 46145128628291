import { Link, useNavigate } from "react-router-dom";
import { Fragment, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import { Ecommerce } from '../.././Ecommerce';
import axios from 'axios';

import cogoToast from 'cogo-toast';

function RejectModal({ show, onHide, id, getPendingSellOrders, setudpateData }) {

    const { account, library, settings } = useContext(Ecommerce);
    const [btnhide, sethidebtn] = useState(false)
    const [hash, sethash] = useState(null)

    const onCloseModal = () => {
        onHide()
    }

    // update order

    // async function btnReject(id) {
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "order", submethod: "updatereject", id: id, key: process.env.REACT_APP_KEY })
    //         .then(res => {
    //             if (res.data) {
    //                 getPendingSellOrders()
    //                 onHide()
    //                 cogoToast.success("Order has been rejected successfully", { position: "bottom-left" });
    //             }
    //         })
    // }
    async function btnReject(id) {
        if (!settings || !library) {
            return
        }
        // console.log("id",id);
        sethidebtn(true)
        let contract = await new library.eth.Contract(JSON.parse(settings.smart_contract_abi), settings.smart_contract);
        var accept = await contract.methods.reject(id).send({ from: account },
            async function (err, transactionId) {
                if (err) {
                    return 0
                }
            }).then((res) => {
                cogoToast.success("Your order has been confirmed.", { position: "bottom-left" });
                sethidebtn(false)
                sethash(res.transactionHash)
                setudpateData(true)
            }).catch((error) => {
                cogoToast.error("Transaction failed", { position: "bottom-left" });
                sethidebtn(false)
            });
        console.log("accept", accept);
    }
    useEffect(() => {
    }, [account, library]);

    return (
        <Modal show={show} onHide={onCloseModal} className="product-quickview-modal-wrapper modal_dialog_box ">
            <Modal.Header closeButton></Modal.Header>

            <div className="modal-body">
                <div className="row">
                    <center>
                        <div className="same-style header-compare">
                            <center>
                                <img src="../assets/img/reject.png" width={60} height={60} />
                            </center> <br />
                            <div className="clear-3 ">
                            {hash ? 
                                <h3>Transaction done</h3>
                                :
                                    <h3>Want to reject?</h3>}
                            </div>
                        </div>
                    </center>
                    <center>
                        <div className="same-style header-compare">
                            <div className="clear-3 ">
                                <br></br>
                                {!btnhide ?
                                    <>
                                        {!hash ?
                                            <>
                                                <button className="button fs-6 header_main_button mb-4" onClick={() => { btnReject(id); }}>Yes</button>&nbsp;&nbsp;&nbsp;
                                                <button className="button fs-6 header_main_button mb-4" onClick={onCloseModal}>No</button>
                                            </>
                                            : ''}
                                    </>
                                    : <img src="assets/img/loading.gif" />}
                            </div>
                        </div>
                    </center>
                </div>
            </div>
        </Modal>
    );
}

RejectModal.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool
};

export default RejectModal;
