import PropTypes from "prop-types";
import React, { useState, useEffect } from 'react';
import ProductGridTwo from "./ProductGridTwo";
import SectionTitleTwo from "../../components/section-title/SectionTitleTwo";
import PersistProvider from "../../store/providers/persist-provider";
import NotFound from "../../pages/other/NotFound";


// const { products } = useSelector((state) => state.product);
// const { searchItem } = useContext(Ecommerce);



const NewProductGrid = ({ category, limit, sortedProducts }) => {
  console.log("category", category);


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log('This will run every second!');
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, []);

  // setInterval(() => {
  //   console.log('Interval triggered');
  // }, 1000);


  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setSeconds(seconds => seconds + 1);
  //   }, 1000);
  //   return () => clearInterval(interval);
  // }, []);

  // const [seconds, setSeconds] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setSeconds(seconds => seconds + 1);
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, []);


  return (





    <>
      {/* {
        sortedProducts === null
          ?

          <PersistProvider />

          : */}

          <>
            <div className="product-area pb-60 section-padding-1">
              {/* {products.filter(prods => prods.name.includes(searchItem)).map((product) => ( */}
              <div className="container-fluid">
                {/* <header className="App-header">
                  {seconds} seconds have elapsed since mounting.
                </header> */}
                <SectionTitleTwo
                  titleText="Product List"
                  subTitleText="Lorem ipsum dolor sit amet conse ctetu."
                  positionClass="text-center"
                  spaceClass="mb-60"
                />
                <div className="row three-column">
                  <ProductGridTwo
                    category={category}
                    type="new"
                    limit={limit}
                    spaceBottomClass="mb-25"
                  />
                </div>
              </div>
              {/* ))} */}
            </div>

            {/* <NotFound /> */}
          </>
{/* 
      } */}

    </>
  );
};

NewProductGrid.propTypes = {
  category: PropTypes.string,
  limit: PropTypes.number
};

export default NewProductGrid;
