import { Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//userpanel 
import UserDashboard from "./components/userpanel/UserDashboard";
import Orders from "./components/userpanel/Orders";
import ProductDetails from "./components/userpanel/ProductDetails";
import Products from "./components/userpanel/Products";

import ProductList from './pages/shop/ProductList'

// home pages
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));


// blog pages

const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
);

const Cart = lazy(() => import("./pages/other/Cart"));

const NotFound = lazy(() => import("./pages/other/NotFound"));

const HeaderButtonModal = lazy(() => import("./components/header/HeaderButtonModal"));

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={<HomeFashion />}
            />

            {/* Homepages */}
            <Route
              path={process.env.PUBLIC_URL + "/home-fashion"}
              element={<HomeFashion />}
            />


            {/* Shop pages */}
            <Route
              path={process.env.PUBLIC_URL + "/shop-grid-standard"}
              element={<ShopGridStandard />}
            />

            {/* Shop product pages */}
            <Route
              path={process.env.PUBLIC_URL + "/product/:id"}
              element={<Product />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
              element={<BlogNoSidebar />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/blog-details-standard"}
              element={<BlogDetailsStandard />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/cart"}
              element={<Cart />}
            />

            {/**New Content added**/}

            <Route
              path={process.env.PUBLIC_URL + "/user-dashboard"}
              element={<UserDashboard />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/orders"}
              element={<Orders />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/product-details"}
              element={<ProductDetails />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/products"}
              element={<Products />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/product-list"}
              element={<ProductList />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/headerbuttonmodal"}
              element={<HeaderButtonModal />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>


        </Suspense>
      </ScrollToTop>
    </Router>
  );
};

export default App;