import React, { useContext, useEffect } from "react";

import { Ecommerce } from '../.././Ecommerce';

import { NavLink, useNavigate } from "react-router-dom";


const UserPanelSidebar = () => {

    const { logout } = useContext(Ecommerce);

    const history = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("isconnected") === 'false') {
            history('/');
        }
        else if (localStorage.getItem("isconnected") === null) {
            history('/');
        }
    }, []);

    function fnlogout() {
        logout()
        history('/')
    }

    return (
        <div className="sidebar-style">
            <div className="sidebar-widget">
                <div className="sidebar-project-wrap mt-30">
                    <div className="single-sidebar-blog">
                        <div className="sidebar-blog-content">
                            <h3>
                                <NavLink to="/user-dashboard" activeClassName='active'>
                                    <b>Dashboard</b>
                                </NavLink>
                            </h3>
                        </div>
                    </div>
                    <div className="single-sidebar-blog">
                        <div className="sidebar-blog-content">
                            <h3>
                                <NavLink to="/orders">
                                    <b>Orders</b>
                                </NavLink>
                            </h3>
                        </div>
                    </div>
                    <div className="single-sidebar-blog">
                        <div className="sidebar-blog-content">
                            <h3>
                                <NavLink to="/products">
                                    <b>Products</b>
                                </NavLink>
                            </h3>
                        </div>
                    </div>
                    <div className="single-sidebar-blog">
                        <div className="sidebar-blog-content">
                            <h3>
                                <a href="" onClick={fnlogout}>
                                    <b>Logout</b>
                                </a>
                            </h3>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default UserPanelSidebar;
