import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import ProductGridListSingle from "../../components/product/ProductGridListSingle";

const ProductGridList = ({
  products,
  spaceBottomClass
}) => {
  // const currency = useSelector((state) => state.currency);
  const currency = process.env.REACT_APP_TICKER;

  return (
    <Fragment>
      {products?.map((product,i) => {
        console.log("productproduct",i);
        return (
          <div className="col-xl-4 col-sm-6" key={i}>
            <ProductGridListSingle
              spaceBottomClass={spaceBottomClass}
              product={product}
              id={i}
              currency={currency}
              // cartItem={
              //   cartItems.find(cartItem => cartItem.id === product.id)
              // }
              // wishlistItem={
              //   wishlistItems.find(
              //     wishlistItem => wishlistItem.id === product.id
              //   )
              // }
              // compareItem={
              //   compareItems.find(
              //     compareItem => compareItem.id === product.id
              //   )
              // }
            />
          </div>
        );
      })}
    </Fragment>
  );
};

ProductGridList.propTypes = {
  products: PropTypes.array,
  spaceBottomClass: PropTypes.string,
};

export default ProductGridList;
