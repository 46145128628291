import { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import { Ecommerce } from '../.././Ecommerce';
import axios from 'axios';

import cogoToast from 'cogo-toast';
import { useState } from "react";

function AcceptModal({ show, onHide, id, getPendingSellOrders, setudpateData, orderreceive }) {

    const { account, settings, library } = useContext(Ecommerce);

    const [btnhide, sethidebtn] = useState(false)
    const [hash, sethash] = useState(null)

    const onCloseModal = () => {
        onHide()
    }

    // update order in smc

    async function btnAccept(id) {
        if (!settings || !library) {
            return
        }
        sethidebtn(true)
        let contract = await new library.eth.Contract(JSON.parse(settings.smart_contract_abi), settings.smart_contract);
        var accept = await contract.methods.accept(id).send({ from: account },
            async function (err, transactionId) {
                if (err) {
                    return 0
                }
            }).then((res) => {
                cogoToast.success("Your order has been confirmed.", { position: "bottom-left" });
                sethidebtn(false)
                sethash(res.transactionHash)
                setudpateData(true)
            }).catch((error) => {
                cogoToast.error("Transaction failed", { position: "bottom-left" });
                sethidebtn(false)
            });
        console.log("accept", accept);
    }
    // async function btnAccept(id) {
    //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, { method: "order", submethod: "updateaccept", id: id, key: process.env.REACT_APP_KEY })
    //         .then(res => {
    //             if (res.data) {
    //                 getPendingSellOrders()
    //                 onHide()
    //                 cogoToast.success("Your order has been confirmed.", { position: "bottom-left" });
    //             }
    //         })
    // }

    useEffect(() => {

    }, [account, library]);

    return (
        <Modal show={show} onHide={onCloseModal} className="product-quickview-modal-wrapper modal_dialog_box ">
            <Modal.Header closeButton></Modal.Header>
            <div className="modal-body">
                <div className="row">
                    <center>
                        <div className="same-style header-compare">
                            <center>
                                <img src="../assets/img/accept.png" width={60} height={60} />
                            </center> <br />
                            <div className="clear-3 ">
                                {hash ?
                                    <h3>Transaction done</h3>
                                    :
                                    <h3>{!orderreceive ? 'Want to accept?' : 'Order received'}</h3>}
                            </div>
                        </div>
                    </center>
                    <center>
                        <div className="same-style header-compare">
                            <div className="clear-3 text-break">
                                <h6>{hash}</h6>
                                <br></br>
                                {!btnhide ?
                                    <>
                                        {!hash ?
                                            <>
                                                <button className="button fs-6 header_main_button mb-4" onClick={() => { btnAccept(id); }}>Yes</button>&nbsp;&nbsp;&nbsp;
                                                <button className="button fs-6 header_main_button mb-4" onClick={onCloseModal}>No</button>
                                            </>
                                            : ''}
                                    </>
                                    : <img src="assets/img/loading.gif" />}
                            </div>
                        </div>
                    </center>
                </div>
            </div>
        </Modal>
    );
}

AcceptModal.propTypes = {
    onHide: PropTypes.func,
    show: PropTypes.bool
};

export default AcceptModal;
