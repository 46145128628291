import PropTypes from "prop-types";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Ecommerce } from "../../Ecommerce";


const ProductGridListSingle = ({
  product,
  currency,
  id
}) => {
  const { library } = useContext(Ecommerce);
  const [img, setimg] = useState("");
  useEffect(() => {
    if (product) {
      console.log("product", product);
      console.log("product.imageLink" );
      var dar = product.imageLink.split(",")
      setimg(dar[0])

    }
  }, [product])

  return (
    <Fragment>
      <div className="col-lg-12 col-md-6 col-sm-12">
        <div className="blog-wrap-2 mb-30">
          <div className="blog-img-2">
            <Link to={process.env.PUBLIC_URL + "/product/" + product?.id}>
              <img
                src={process.env.REACT_APP_PRODUCT_IMAGE_PATH + img + '.jpg'}
                alt={product.name}
              />
              {/* <img
                src={"../" + process.env.REACT_APP_PRODUCT_IMAGE_PATH + product.p_img[0]}
                alt={product.p_name}
              /> */}
            </Link>
          </div>
          <div className="blog-content-2 grid_card_box">
            <h4>
              <div className="row">
                <div className="col-lg-9 col-md-9 col-12">
                  <Link to={process.env.PUBLIC_URL + "/product/" + product?.id}>
                    <b className="fs-6">{product.name}</b>
                  </Link>
                </div>
                <div className="col-lg-3 col-md-3 col-12">
                  <span className="fs-6">
                    <Link to={"/cart"} state={{ id: product?.id, qty: 1 }}>
                      <button className="grid_buy_button" title="Buy Now">
                        <i className="pe-7s-cart " />
                      </button>
                    </Link>
                  </span>
                </div>

                <div className="col-lg-9 col-md-9 col-12">
                  <Fragment>
                    <span className="old fs-6">
                      <Link to={process.env.PUBLIC_URL + "/product/" + product?.id}>
                        {library?.utils?.fromWei(product.price)} {currency}
                      </Link>
                    </span>
                  </Fragment>
                </div>
              </div>
            </h4>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ProductGridListSingle.propTypes = {
  cartItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
  currency: PropTypes.shape({}),
  product: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.shape({})
};

export default ProductGridListSingle;
