
import { useLocation, Link, useNavigate } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import UserPanelSidebar from "./UserPanelSidebar";

import { Fragment, useState, useEffect, useRef, useContext, useMemo } from 'react';
import Paginator from 'react-hooks-paginator';
import { useSelector } from "react-redux";
import { getSortedProducts } from '../../helpers/product';

import axios from 'axios';

import { Ecommerce } from '../.././Ecommerce';
import AcceptModal from "./AcceptModal";
import RejectModal from "./RejectModal";
import AcceptBuyerModal from "./AcceptBuyerModal";

const countOnPage = 10;
const range = 2;


const Orders = () => {

  const { account, formatAddress, copyaddress, library, settings } = useContext(Ecommerce);
  const currency = process.env.REACT_APP_TICKER;

  let { pathname } = useLocation();

  const [sortType, setSortType] = useState('');
  const [sortValue, setSortValue] = useState('');
  const [filterSortType, setFilterSortType] = useState('');
  const [filterSortValue, setFilterSortValue] = useState('');
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [orderreceive, setorderreceive] = useState(false);
  const { products } = useSelector((state) => state.product);

  const pageLimit = 15;

  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);

  const [acceptid, setacceptid] = useState('');
  const [rejectid, setrejectid] = useState('');
  const [acceptbuyerid, setacceptbuyerid] = useState('');

  const [orderlistdata, setorderlistdata] = useState([]);
  const [selecttype, settype] = useState("");
  //pagination 

  const [page, setPage] = useState(1);

  const [datalength, setdatalength] = useState(0);

  const rangeStart = useMemo(() => {
    const start = page - range;
    return start > 0 ? start : 1;

  }, [page]);

  const rangeEnd = useMemo(() => {
    const end = page + range;
    return end < datalength ? end : datalength;
  }, [page, datalength]);

  const pages = useMemo(() => {
    return Array.from({ length: rangeEnd }, (_, index) => index + 1).slice(
      rangeStart - 1
    );
  }, [rangeStart, rangeEnd]);

  // const fetchOrderData = async (type) => {
  //   if (type === 'Buyer' || type === 'buyer') {
  //     setorderlistdata([]);
  //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
  //       method: "order",
  //       submethod: "getbybuy",
  //       address: account,
  //       key: process.env.REACT_APP_KEY
  //     })
  //       .then(res => {


  //         setLoading1(false);
  //         if (res.data.error) {
  //           return ''
  //         }
  //         setorderlistdata(res.data.data);
  //         setdatalength(Math.ceil(res.data.data.length / countOnPage));
  //         settype('Buyer');
  //         console.log("ffffff11", selecttype)
  //       })
  //   }
  //   else if (type === 'Seller' || type === 'seller') {
  //     setorderlistdata([]);
  //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
  //       method: "order",
  //       submethod: "getbysell",
  //       address: account,
  //       key: process.env.REACT_APP_KEY
  //     })
  //       .then(res => {
  //         console.log("res seller order data : ", res.data)

  //         setLoading1(false);
  //         if (res.data.error) {
  //           return ''
  //         }
  //         settype('Seller');
  //         setorderlistdata(res.data.data);
  //         setdatalength(Math.ceil(res.data.dazta.length / countOnPage));
  //         console.log("ffffff", selecttype)
  //       })
  //   }
  //   else {
  //     setorderlistdata([]);
  //     await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
  //       method: "order",
  //       submethod: "getall",
  //       address: account,
  //       key: process.env.REACT_APP_KEY
  //     })
  //       .then(res => {
  //         console.log("res getall order data : ", res.data)

  //         setLoading1(false);
  //         if (res.data.error) {
  //           return ''
  //         }

  //         settype('Buyer');
  //         setorderlistdata(res.data.data);
  //         setdatalength(Math.ceil(res.data.dazta.length / countOnPage));

  //       })

  //   }
  // };


  const fetchOrderData = async (id) => {
    settype(id)
    if (!settings || !library) {
      return
    }
    // console.log("id",id);
    let contract = await new library.eth.Contract(JSON.parse(settings.smart_contract_abi), settings.smart_contract);
    //buyer
    if (id === 'Buyer') {
      var buyer = await contract.methods.getorderbybuyer(account).call();
      var ttlpenbuy = [];
      var ttlpensell = [];
      for (let i = 0; i < buyer.length; i++) {
        const e = buyer[i];
        if (e.buyer !== '0x0000000000000000000000000000000000000000') {
          // ttlpenbuy.push(e)
          var getprd = await contract.methods.products(e.productId).call();
          ttlpenbuy.push({
            amount: e.amount,
            buyer: e.buyer,
            buyerOk: e.buyerOk,
            feeRatio: e.feeRatio,
            feeWallet: e.feeWallet,
            odertime: e.odertime,
            orderId: e.orderId,
            productId: e.productId,
            qty: e.qty,
            prdname: getprd.name,
            received: e.received,
            seller: e.seller,
            sellerOk: e.sellerOk,
            status: e.status,
          })
        }
      }
      setorderlistdata(ttlpenbuy);
      setdatalength(Math.ceil(ttlpenbuy.length / countOnPage));
    } else {
      //sell
      var seller = await contract.methods.getorderbyseller(account).call();
      var ttlpensell = [];
      for (let i = 0; i < seller.length; i++) {
        const e = seller[i];
        if (e.seller !== '0x0000000000000000000000000000000000000000') {
          // ttlpensell.push(e)
          var getprd = await contract.methods.products(e.productId).call();
          ttlpensell.push({
            amount: e.amount,
            buyer: e.buyer,
            buyerOk: e.buyerOk,
            feeRatio: e.feeRatio,
            feeWallet: e.feeWallet,
            odertime: e.odertime,
            orderId: e.orderId,
            productId: e.productId,
            qty: e.qty,
            prdname: getprd.name,
            received: e.received,
            seller: e.seller,
            sellerOk: e.sellerOk,
            status: e.status
          })
        }
      }
      // console.log("slel",ttlpensell);
      setorderlistdata(ttlpensell);
      setdatalength(Math.ceil(ttlpensell.length / countOnPage));
    }

    // console.log("buyert", buyer);
  }
  const updateData = async () => {
    fetchOrderData(selecttype)
  }
  useEffect(() => {
    let sortedProducts = getSortedProducts(products, sortType, sortValue);
    const filterSortedProducts = getSortedProducts(sortedProducts, filterSortType, filterSortValue);
    sortedProducts = filterSortedProducts;
    setSortedProducts(sortedProducts);
    setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
    fetchOrderData('Buyer');
  }, [offset, products, sortType, sortValue, filterSortType, filterSortValue, account, library]);

  return (

    <Fragment>
      <SEO
        titleTemplate="Orders"
        description=""
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            { label: "Orders", path: process.env.PUBLIC_URL + pathname }
          ]}
        />

        <div className="blog-area pt-100 pb-100">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-9">
                <div className="ml-20">

                  <div className="row">

                    <h3 className="cart-page-title">Orders</h3>
                    <div className="shop-top-bar mb-35">
                      <div class="select-shoing-wrap">
                        <div class="shop-select">
                          <select onChange={(e) => fetchOrderData((e.target.value))}>
                            {/* <option>Select Type</option> */}
                            <option>Buyer</option>
                            <option>Seller</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <br /><br /><br />
                    {/* selecttype===={selecttype} */}
                    <Fragment>
                      <h3 className="cart-page-title">List of Orders</h3>
                      <div className="row">
                        <div className="col-12">
                          <div className="table-content table-responsive cart-table-content">
                            <table className="tbl">
                              <thead>
                                <tr>
                                  {/* <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th> */}
                                  <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                  <th>#</th>
                                  <th>Order ID</th>
                                  <th>Product Details</th>
                                  <th>Amount</th>
                                  <th>Datetime</th>
                                </tr>
                              </thead>
                              <tbody>

                                {orderlistdata?.slice((page - 1) * countOnPage, page * countOnPage).map((e, index) => {
                                  var date = new Date(e.odertime * 1000)
                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          {selecttype}
                                          <br />
                                          {selecttype === 'Buyer' ?
                                            e.status === "0" ?
                                              <>
                                                <div className="product-wrap mb-45">
                                                  <div class="product-img-badges">
                                                    <span class="purple">Processing</span>
                                                  </div>
                                                </div>
                                              </>
                                              : e.status === "1" ?
                                                <>
                                                  <div className="product-wrap mb-45">
                                                    <div class="product-img-badges">
                                                      <span class="purple">Shipping</span>
                                                    </div>
                                                  </div>

                                                  <a onClick={() => { setModalShow(true); setacceptid(e.orderId); setorderreceive(true) }}>
                                                    <img src="../assets/img/receive.png" width={40} height={40} />
                                                  </a>
                                                  <br />
                                                </>
                                                : e.status === "3" ?
                                                  <>
                                                    <div className="product-wrap mb-45">
                                                      <div class="product-img-badges">
                                                        <span class="purple">Rejected</span>
                                                      </div>
                                                    </div>
                                                  </>
                                                  :
                                                  <>
                                                    <div className="product-wrap mb-45">
                                                      <div class="product-img-badges">
                                                        <span class="purple">Received</span>
                                                      </div>
                                                    </div>
                                                  </>
                                            :
                                            e.status === "0" ?
                                              <>
                                                <div className="product-wrap mb-45">
                                                  <div class="product-img-badges">
                                                    <span class="purple">Processing</span>
                                                  </div>
                                                </div>

                                                <a onClick={() => { setModalShow(true); setacceptid(e.orderId); setorderreceive(false) }}>
                                                  <img src="../assets/img/accept.png" width={40} height={40} />
                                                </a>
                                                &nbsp;
                                                <a onClick={() => { setModalShow2(true); setrejectid(e.orderId) }}>
                                                  <img src="../assets/img/reject.png" width={40} height={40} />
                                                </a>
                                                <br />
                                              </>
                                              : e.status === "1" ?
                                                <>
                                                  <div className="product-wrap mb-45">
                                                    <div class="product-img-badges">
                                                      <span class="purple">Shipping</span>
                                                    </div>
                                                  </div>
                                                </>
                                                : e.status === "3" ?
                                                  <>
                                                    <div className="product-wrap mb-45">
                                                      <div class="product-img-badges">
                                                        <span class="purple">Rejected</span>
                                                      </div>
                                                    </div>
                                                  </>
                                                  :
                                                  <>
                                                    <div className="product-wrap mb-45">
                                                      <div class="product-img-badges">
                                                        <span class="purple">Sold</span>
                                                      </div>
                                                    </div>
                                                  </>
                                          }
                                        </td>
                                        <td>{index + 1}</td>
                                        <td>
                                          {formatAddress(e.orderId)}&nbsp;
                                          <Link to="" onClick={() => copyaddress(e.orderId)}>
                                            <i class="fa fa-copy" />
                                          </Link>
                                        </td>

                                        <td>
                                          <b>Item:</b> {e.prdname} <br />
                                          <b>Qty:</b> {e.qty} <br />
                                          <b>Price:</b> {e.p_rate} {currency}<br />
                                          <b>Seller:</b>&nbsp;
                                          {formatAddress(e.seller)}&nbsp;
                                          <Link to="" onClick={() => copyaddress(e.seller)}>
                                            <i class="fa fa-copy" />
                                          </Link>
                                        </td>
                                        <td>
                                          {library.utils.fromWei(e.amount)} {currency}
                                        </td>
                                        <td>{date.toLocaleDateString('default')} {date.toLocaleTimeString("default")}</td>

                                      </tr>
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="pro-pagination-style text-center mt-30">

                        {pages?.map((item) => {
                          return (
                            <>
                              <span class={page == item ? "page-item active" : "page-item"} type="button" key={item} onClick={() => setPage(item)}>
                                <Paginator
                                  totalRecords={item}
                                  pageLimit={pageLimit}
                                  pageNeighbours={2}
                                  setOffset={setOffset}
                                  currentPage={currentPage}
                                  setCurrentPage={setCurrentPage}
                                  pageContainerClass="mb-0 mt-0"
                                  pagePrevText="«"
                                  pageNextText="»"
                                />
                              </span>

                            </>
                          );
                        })}


                      </div>

                    </Fragment>

                  </div>

                </div>
              </div>
              <div className="col-lg-3">

                <UserPanelSidebar />

              </div>
            </div>
          </div>
        </div>

      </LayoutOne>

      <AcceptModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        id={acceptid}
        orderreceive={orderreceive}
        setudpateData={updateData}
        getPendingSellOrders={fetchOrderData}
      />

      <RejectModal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        id={rejectid}
        setudpateData={updateData}
        getPendingSellOrders={fetchOrderData}
      />

      <AcceptBuyerModal
        show={modalShow3}
        onHide={() => setModalShow3(false)}
        id={acceptbuyerid}
        setudpateData={updateData}
        getPendingSellOrders={fetchOrderData}
      />

    </Fragment>

  );
};

export default Orders;
