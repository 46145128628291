
import { useLocation, Link, useNavigate } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import UserPanelSidebar from "./UserPanelSidebar";

import { Fragment, useState, useEffect, useRef, useContext, useMemo } from 'react';
import Paginator from 'react-hooks-paginator';
import { useSelector } from "react-redux";
import { getSortedProducts } from '../../helpers/product';

import AddProductModal from "./AddProductModal";

import axios from 'axios';
import { Ecommerce } from '../.././Ecommerce';

const countOnPage = 10;
const range = 2;

const Products = () => {

  const { account, library, settings } = useContext(Ecommerce);
  const currency = process.env.REACT_APP_TICKER;

  let { pathname } = useLocation();

  const [modalShow, setModalShow] = useState(false);

  const [sortType, setSortType] = useState('');
  const [sortValue, setSortValue] = useState('');
  const [filterSortType, setFilterSortType] = useState('');
  const [filterSortValue, setFilterSortValue] = useState('');
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);
  const { products } = useSelector((state) => state.product);

  const pageLimit = 15;

  const [productlistdata, setproductlistdata] = useState([]);

  //pagination 

  const [page, setPage] = useState(1);

  const [datalength, setdatalength] = useState(0);

  const rangeStart = useMemo(() => {
    const start = page - range;
    return start > 0 ? start : 1;

  }, [page]);

  const rangeEnd = useMemo(() => {
    const end = page + range;
    return end < datalength ? end : datalength;
  }, [page, datalength]);

  const pages = useMemo(() => {
    return Array.from({ length: rangeEnd }, (_, index) => index + 1).slice(
      rangeStart - 1
    );
  }, [rangeStart, rangeEnd]);

  // const fetchProducts = async (type) => {
  //   await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
  //     method: "products",
  //     submethod: "getbyaddress",
  //     address: account,
  //     key: process.env.REACT_APP_KEY
  //   })
  //     .then(res => {


  //       setLoading1(false);
  //       if (res.data.error) {
  //         return ''
  //       }
  //       setproductlistdata(res.data.data);
  //       setdatalength(Math.ceil(res.data.data.length / countOnPage));

  //     })
  // };

  const fetchProducts = async () => {
    if (!settings || !library) {
      return
    }
    let contract = await new library.eth.Contract(JSON.parse(settings.smart_contract_abi), settings.smart_contract);
    var get = await contract.methods.getproductbyuser(account).call();
    var getdata = [];
    for (let i = 0; i < get.length; i++) {
      const e = get[i];
      if (e.seller !== '0x0000000000000000000000000000000000000000') {
        getdata.push(e)
      }

    }
    setproductlistdata(getdata);
  }

  useEffect(() => {
    let sortedProducts = getSortedProducts(products, sortType, sortValue);
    const filterSortedProducts = getSortedProducts(sortedProducts, filterSortType, filterSortValue);
    sortedProducts = filterSortedProducts;
    setSortedProducts(sortedProducts);
    setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
    // fetchProducts();
  }, [offset, products, sortType, sortValue, filterSortType, filterSortValue, account]);

  useEffect(() => {
    fetchProducts()
  }, [account, settings,library])

  return (
    <Fragment>
      <SEO
        titleTemplate="Products"
        description=""
      />
      <LayoutOne
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            { label: "Products", path: process.env.PUBLIC_URL + pathname }
          ]}
        />
        <div className="blog-area pt-100 pb-100">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-lg-9">
                <div className="ml-20">

                  <div className="row">

                    <Fragment>

                      <div className="col-lg-8">
                        <h3 className="cart-page-title">List of Products</h3>
                      </div>
                      <div className="col-lg-4">
                        <div class="productdetailsbtn">
                          <Link to="#" onClick={() => setModalShow(true)}>Add Product</Link>
                          <AddProductModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            fetchProducts={fetchProducts}
                          />

                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="table-content table-responsive cart-table-content">
                            <table className="tbl">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Category</th>
                                  <th>Item Name</th>
                                  {/* <th>Qty.</th> */}
                                  <th>Rate</th>
                                  <th>Status</th>
                                  {/* <th>Action</th> */}
                                </tr>
                              </thead>
                              <tbody>

                                {productlistdata?.slice((page - 1) * countOnPage, page * countOnPage).map((e, i) => {
                                  return (
                                    <>
                                      <tr>

                                        <td>
                                          {i + 1}
                                        </td>

                                        <td>
                                          {e.category}
                                        </td>

                                        <td>
                                          {e.name}
                                        </td>

                                        {/* <td>
                                          <b>Total:</b>  {e.rate} <br />
                                          <b>Available:</b>  {e.rate} <br />
                                          <b>Sold:</b>  {e.sold}
                                        </td> */}

                                        <td>
                                          {library?.utils?.fromWei(e.price)} {currency}
                                        </td>

                                        <td>{e.status ?
                                          <>
                                            <div className="product-wrap mb-45">
                                              <div class="product-img-badges">
                                                <span class="purple">Active</span>
                                              </div>
                                            </div>
                                          </>
                                          :
                                          <>
                                            <div className="product-wrap mb-45">
                                              <div class="product-img-badges">
                                                <span class="pink">Inactive</span>
                                              </div>
                                            </div>
                                          </>
                                        }
                                        </td>

                                        {/* <td>
                                          <Link to="#"><i class="fa fa-edit" aria-hidden="true"></i></Link> |&nbsp;
                                          <Link to="#"><i class="fa fa-trash" aria-hidden="true"></i></Link>
                                        </td> */}

                                      </tr>
                                    </>

                                  );
                                })}


                              </tbody>
                            </table>


                          </div>
                        </div>
                      </div>

                      <div className="pro-pagination-style text-center mt-30">

                        {pages?.map((item) => {
                          return (
                            <>
                              <span class={page == item ? "page-item active" : "page-item"} type="button" key={item} onClick={() => setPage(item)}>
                                <Paginator
                                  totalRecords={item}
                                  pageLimit={pageLimit}
                                  pageNeighbours={2}
                                  setOffset={setOffset}
                                  currentPage={currentPage}
                                  setCurrentPage={setCurrentPage}
                                  pageContainerClass="mb-0 mt-0"
                                  pagePrevText="«"
                                  pageNextText="»"
                                />
                              </span>

                            </>
                          );
                        })}


                      </div>

                    </Fragment>

                  </div>

                </div>
              </div>
              <div className="col-lg-3">

                <UserPanelSidebar />

              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default Products;
